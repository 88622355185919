"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormDataClient = void 0;
const client_models_1 = require("../client-models");
const client_types_1 = require("../client-types");
const constants_1 = require("../constants");
const functions_1 = require("../functions");
const attachments_1 = require("./attachments");
/** Client for v0/projects/:id/form-data APIs */
class FormDataClient {
    constructor(client, options) {
        this.client = client;
        this.options = options;
        this.attachmentsClient = new attachments_1.AttachmentsClient(this.client, options);
    }
    query(query, options) {
        return this.client.get(this.url(), { ...options, query: (0, functions_1.stringifyQuery)(query) });
    }
    count(query, options) {
        return this.client.get(this.url(constants_1.APIPath.ResourcesCount), { ...options, query: (0, functions_1.stringifyQuery)(query) });
    }
    async get(id, options) {
        const formData = await this.client.get(this.url(id), options);
        formData._bucket = formData._bucket ?? this.options.bucket; // Amend legacy FormData missing _bucket
        return formData;
    }
    async getRevision(id, options) {
        const headers = await this.client.head(this.url(id), options);
        return (0, functions_1.getEtag)(headers)?.etag;
    }
    save(data, options) {
        if (!data.id) {
            // Save
            return this.client.post(this.url(), { body: data });
        }
        // Update
        const ifMatchValue = (0, functions_1.buildIfMatchHeaderValue)([options?.revision]);
        const headers = ifMatchValue ? new Headers({ [client_types_1.HeaderKeys.IfMatch]: ifMatchValue }) : undefined;
        return this.client.put(this.url(data.id), data, { headers });
    }
    uploadAttachment(file, options) {
        return this.client.upload(file, this.fileUrl(), options);
    }
    getRevisions(id, options) {
        return this.client.get(this.url(id, constants_1.APIPath.FormDataRevisions), options);
    }
    getRevisionData(id, rev, options) {
        return this.client.get(this.url(id, constants_1.APIPath.FormDataRevisions, rev), options);
    }
    /**
     * Generate the attachment download URL including the download token as param
     * @param attachmentId
     * @param params
     * @returns
     */
    getAttachmentUrl(attachmentId, params = {}) {
        return this.attachmentsClient.getAttachmentUrl(attachmentId, params);
    }
    getDownloadUrl(query, format = client_models_1.Formats.Csv) {
        return (0, functions_1.composeDownloadUrl)(this.url(), format, query);
    }
    getPrintPDFUrl(id, revision, mode) {
        const parts = [id];
        const params = {};
        if (revision) {
            parts.push(constants_1.APIPath.FormDataRevisions, revision);
        }
        if (mode) {
            params.mode = mode;
        }
        return (0, functions_1.appendParamsToUrl)(this.url(...parts), params);
    }
    url(...extra) {
        return this.client.buildUrl(...this.baseUrlParts, this.options.bucket, ...extra);
    }
    fileUrl(...extra) {
        return this.client.buildUrl(...this.baseUrlParts, constants_1.APIPath.FormDataFiles, ...extra);
    }
    get baseUrlParts() {
        const projectParts = [constants_1.APIPath.Projects, this.options.projectId];
        if (this.options.preview) {
            projectParts.push(constants_1.APIPath.Preview);
        }
        return [...projectParts, constants_1.APIPath.FormData];
    }
}
exports.FormDataClient = FormDataClient;
