"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttachmentsClient = void 0;
const constants_1 = require("../constants");
/**
 * Client for v0/projects/:id/form-data/_files
*/
class AttachmentsClient {
    constructor(client, options) {
        this.client = client;
        this.options = options;
    }
    /**
     * Generate the attachment download URL including the download token as param
     * @param attachmentId
     * @param params
     * @returns
     */
    async getAttachmentUrl(attachmentId, params = {}) {
        const url = this.url(attachmentId);
        const { token } = await this.client.getDownloadToken(url);
        const searchParams = new URLSearchParams(Object.assign(params, { _dlt: token }));
        return `${url}?${searchParams.toString()}`;
    }
    url(...extra) {
        const parts = [constants_1.APIPath.Projects, this.options.projectId];
        if (this.options.preview) {
            parts.push(constants_1.APIPath.Preview);
        }
        parts.push(constants_1.APIPath.FormData, constants_1.APIPath.FormDataFiles);
        return this.client.buildUrl(...parts, ...extra);
    }
}
exports.AttachmentsClient = AttachmentsClient;
