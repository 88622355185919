<div class="uf-app-bar ">
    <button (click)="runtime.close()" type="button" class="uf-action tertiary" title="Close">
        <uf-icon name="close" />
    </button>
    <h3>Select Asset</h3>
</div>

<uf-panel class="grow">
    <div class="uf-grid pad">

        <uc-asset [control]="assetControl" [type]="type" [autofocus]="true" [(value)]="asset" class="col-12" />

        <ng-container *ngIf="asset && !hasData && asset.type === mediaType.Image">

            <div class="col-12 uf-grid">
                <img [src]="asset.url" alt="" class="thumb col">
                <uf-data-display-list class="col-10 row-gap-sm">
                    <dt>{{consoleNameLabel}}</dt>
                    <dd>{{asset.consoleName}}</dd>
                    <dt>Description</dt>
                    <dd>{{asset.description}}</dd>
                    <dt>Original Size</dt>
                    <dd>{{asset.width}}x{{asset.height}}</dd>
                </uf-data-display-list>
            </div>

            <uf-select [options]="alignmentOptions" [(value)]="alignment" (valueChange)="updateAlignment()"
                class="col-4" label="Alignment" nameProperty="name" valueProperty="identifier" />
            <uf-select [options]="imageSizeOptions" [(value)]="imageSize"
                (valueChange)="directive = updateDirective(true)" class="col-4" label="Image Width" nameProperty="name"
                valueProperty="identifier" />
            <uf-select [options]="radioOptions" [(value)]="ratio" (valueChange)="directive = updateDirective(true)"
                class="col-4" label="Aspect Ratio" nameProperty="name" valueProperty="value" />
            <div class="col-12">
                <button [disabled]="!ratio" (click)="cropImage()" type="button"
                    class="uf-button secondary">Crop</button>
            </div>
        </ng-container>

        <ng-container *ngIf="asset">
            <div *ngIf="directive" [markdownRenderer]="directive" class="col-12 uf-box body-copy flat pad-sides"></div>
        </ng-container>
    </div>
</uf-panel>

<div class="row space-children pad">
    <button (click)="runtime.close()" type="button" class="uf-button tertiary right">Close</button>
    <button (click)="add()" type="button" class="uf-button primary">Add</button>
</div>